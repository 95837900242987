import styled from "styled-components";

import SVG from './Background';

const Background = styled.div`
`;

const SVGWrapper = styled.div`
	position: absolute;
	height: 100%;
	width: 100%;

`;

interface Props {
	isMobile: boolean
}

const Landing = (props: Props) => {
	// Make vh behave properly on mobile too
	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', `${vh}px`);

	window.addEventListener('resize', () => {
		// We execute the same script as before
		let vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	});

	return (
		<Background>
			<SVGWrapper>
				<SVG isMobile={props.isMobile}/> 
			</SVGWrapper>
		</Background>
	);
};

export default Landing
