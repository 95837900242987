import React from 'react';

import { createGlobalStyle } from 'styled-components';
import { useState, useEffect} from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import CursorImg from "./assets/images/cursor.png"

import Welcome from './components/Welcome';
import Landing from './components/Landing';

const GlobalStyle = createGlobalStyle`
	* {
		cursor: url(${CursorImg}), auto;

		margin: 0;
		padding: 0;
		box-sizing: border-box;
		text-decoration: none;

		min-height: 100%;
	}
	html {
		/* Always show a scroll-bar */
		overflow-y: hidden;
	}
`;


function App() {
	const [matches, setMatches] = useState(false);

	useEffect(() => {
		const media = window.matchMedia('(max-width: 650px)');
		if (media.matches !== matches) {
			setMatches(media.matches);
		}
		const listener = () => { setMatches(media.matches); }
		console.log(matches);
		media.addListener(listener);
		return () => media.removeListener(listener)
	}, [matches])

  return (
		<>
		<GlobalStyle/>
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Landing isMobile={matches}/>} />
				<Route path="/welcome" element={<Welcome isMobile={matches}/>} />
			</Routes>
		</BrowserRouter>
		</>
  );
}

export default App;
