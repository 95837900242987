import styled from "styled-components";

import MobileImg from "../assets/images/Letter_Mobile.jpeg"
import DesktopImg from "../assets/images/Letter_Desktop.jpeg"

const LetterWrapper = styled.div<{ mobile: boolean}>`
		width: 100%;
		height: calc(var(--vh, 1vh) * 100);
		display: flex;
		justify-content: center;

		img {
			width: 100%;
			object-fit: cover;
		}
`;


interface Props {
	isMobile: boolean
}

const Welcome = (props: Props) => {
	// Make vh behave properly on mobile too
	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', `${vh}px`);

	window.addEventListener('resize', () => {
		// We execute the same script as before
		let vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	});

	return (
		<LetterWrapper mobile={true}>
			<img src={props.isMobile ? MobileImg : DesktopImg} alt="Letter"/>
		</LetterWrapper>
	);
};

export default Welcome
